<script setup>
import { ref } from "vue";
const smallTitle = ref("firma");
const bigTitle = ref("Documento");
const pdfTitle = ref("Firma contratto");
const document = ref({
  url: "https://pdfobject.com/pdf/sample.pdf",
});
</script>

<template>
  <master-layout :smallTitle="smallTitle" :bigTitle="bigTitle">
    <h1>{{ pdfTitle }}</h1>
    <div style="height: 65vh">
      <iframe
        :src="document.url"
        frameBorder="0"
        scrolling="auto"
        width="100%"
        height="100%"
      ></iframe>

      <div
        class="w-full flex flex-column justify-content-between align-items-center pt-2"
      >
        <button class="w-full p-d button-primary mb-2">
          Firma il documento
        </button>
        <button class="w-full p-d button-primary">
          Archivia il documento firmato
        </button>
      </div>
    </div>
  </master-layout>
</template>
